.signup_form_signup_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    line-height: 115.1%;
    margin-top: 100px;
    margin-bottom: 10px;
    color: #4B41BA;
}

.signup_form_click_login_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 85%;
    line-height: 115.1%;
    color: rgba(0, 0, 0, 0.85);
}

/*
.signup_form_user_id_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_user_id_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_email_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
    margin-top: 0px;
}

.signup_form_email_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_first_name_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_first_name_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_last_name_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_last_name_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_city_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_city_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_state_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_state_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_password_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_password_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_confirm_password_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_confirm_password_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_signup_button_location_detail {
    background: #5566F6;
    border-radius: 26px;
    border-style: none;
    margin-bottom: 10px;
}*/

.signup_form_signup_button_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 160%;
    line-height: 19px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.signup_form_click_privacy_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    margin-right: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.85);
}