.events__box {
    background: #fff;
    padding-bottom: 2rem;
    border-radius: 5px;
    box-shadow: -1px 2px 34px -13px rgba(0,0,0,0.75);
    text-align: left;
    overflow: hidden;
}

.events__box-image {
    height: 250px;
    width: 100%;
    padding: 20px;
}

.events__box-title {
    background: #f9f9ff;
    border-left: 3px solid #ff1857;
    margin-bottom: 30px;
  }
  
.events__box-title h2 {
    color: #222222;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 40px;
    padding-left: 15px;
    margin-bottom: 0px;
}


.events__box-text {
    margin-left: 20px;
    margin-top: 10px;
}

.events__box-text h4 {
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}