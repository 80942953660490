.event_detail img{
    height: 300px;
    width: 100%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.event_detail h4 {
    font-size: 36px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 20px;
    margin-top: 30px;
}

.event_detail p {
    font-size: 14px;
    color: #777777;
    margin-bottom: 15px;
    display: block;
}

.event_detail-top {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.event_detail-top-left {
    width: 66.666666%;
    align-self: center;
}

.event_detail-top-right {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    width: 33.333333%;
}

.event_detail-middle {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.event_detail-bottom {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}