.uploadPhoto {
  background-image: url("../../assets/photo\ 1.png");
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position-x: center;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.uploadPhotoWithExists {
  text-align: center;
  cursor: pointer;
  width: 190px;
  height: 133px;
  background-image: url("../../assets/Group\ 4274.png");
}

.uploadContainer {
  border: 1px dashed #c5c5c5;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 40px;
  min-width: 620px;
  min-height: 420px;
}

.uploadContainerWithDrag {
  border: 1px dashed #c5c5c5;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 40px;
  min-width: 620px;
  min-height: 420px;
}

.displayPhoto {
  display: grid;
  width: 620px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 9px;
  padding-bottom: 9px;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;  
}
/* display: 'grid', marginTop: '2%', marginBottom: '2%', width:'95%', marginLeft: 'auto', marginRight: 'auto', gridRowGap: '30px', gridTemplateColumns: 'repeat(3, 1fr)' */
