.login_form_login_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    line-height: 115.1%;
    margin-top: 140px;
    margin-bottom: 5px;
    color: #4B41BA;
}

.login_form_click_signup_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 85%;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
}
/*
.login_form_email_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    color: #000000;
}

.login_form_email_input_detail {
    background-color: #F6F6F6;
    border-radius: 20px;
    margin-left: 10px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.login_form_password_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    margin-top: 10px;
    color: #000000;
}

.login_form_password_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    margin-left: 10px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.login_form_loin_button_location_detail {
    background: #5566F6;
    border-radius: 26px;
    margin-bottom: 10px;
    border-style: none;
}*/

.login_form_login_button_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 160%;
    line-height: 19px;
    margin-top: 10px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

