.categoryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, max-content));
}
.categoryBtn {
  width: 110px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2);
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px white solid;
  outline: none;
}

.categoryBtnFocus {
  width: 110px;
  height: 45px;
  background: #ffffff;
  /* box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2); */
  border-radius: 20px;
  border: 1px solid #5666f6;
  margin-right: 20px;
  margin-bottom: 20px;
  outline: none;
}

.categoryIcon {
  outline: none;
  border: white;
  background-color: transparent;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset;
}

.onlineInput {
  width: 100%;
  height: 40px;
  background-image: url("../../assets/online.png");
  background-position-y: center;
  background-position-x: 98%;
  background-repeat: no-repeat;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
}

.offlineInput {
  width: 100%;
  height: 40px;
  background-position-y: center;
  background-position-x: 98%;
  background-repeat: no-repeat;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
  background-image: url("../../assets/location.png");
}

.eventBtn {
  width: 110px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(243, 171, 171, 0.2);
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px white solid;
  outline: none;
}

.eventBtnFocus {
  width: 110px;
  height: 45px;
  background: #ffffff;
  /* box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2); */
  border-radius: 20px;
  border: 1px solid #5666f6;
  margin-right: 20px;
  margin-bottom: 20px;
  outline: none;
}
