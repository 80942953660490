.banner {
    background-image: url("../../assets/4\ 2.png");
    width: 100%;
    background-size: cover;
    height: 240px;
}

.ticketContainer {
    position: relative;
    /* background-image: url("../../assets/Group\ 4725.png"); */
    /* margin-left: -50px; */
    /* width: 80%; */
    min-height: 300px;
    /* background-repeat: no-repeat; */
    /* position: absolute; */
    /* justify-content: center; */
    /* display: flex; */
    box-shadow: 0px 24px 50px rgba(184, 185, 190, 0.35);
    border-radius: 15px;
    padding: 41px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ticketContainer::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: 30px;
    left: -20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    box-shadow: 0px 24px 50px rgba(184, 185, 190, 0.35);
    border-right: 20px solid #FFFFFF;
}

.ticketSubContainer {
    border-radius: 7px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: #F4F5FF;
    margin-bottom: 30px;
    padding: 24px;
}

.ticketSubContainer:last-child {
    margin-bottom: 0px;
}

.ticketActions {
    position: relative;
    padding-top: 5px;
    text-align: right;
}

.DayPicker-Day--gradeSoldDate:not(.DayPicker-Day--outside) {
    background-color: yellow;
}