.articles__box {
    background: #fff;
    padding-bottom: 2rem;
    border-radius: 5px;
    box-shadow: -1px 2px 34px -13px rgba(0,0,0,0.75);
    text-align: left;
    overflow: hidden;
}

.articles__box-image-container {
    background: none;
    float: left;
    height: 100%;
    width: 320px;
    position: relative;
}

.articles__box-image {
    height: 100%;
    width: 300px;
    padding: 30px;
}

.articles__box-title {
    background: #f9f9ff;
    border-left: 3px solid #ff1857;
    margin-bottom: 30px;
  }
  
.articles__box-title h2 {
    color: #222222;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 40px;
    padding-left: 15px;
    margin-bottom: 0px;
}


.articles__box-text {
    background-color: #fff;
    float: left;
    height: 100%;
    padding: 12px 15px;
    margin-top: 20px;
    position: relative;
    width: 65%;
}

.articles__box-text h4 {
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}