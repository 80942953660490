.events__box {
    background: #fff;
    padding-bottom: 2rem;
    border-radius: 5px;
    box-shadow: -1px 2px 34px -13px rgba(0,0,0,0.75);
    text-align: left;
    overflow: hidden;
}

.events__box-image {
    height: 250px;
    width: 100%;
    padding: 20px;
}

.events__box-title {
    background: #f9f9ff;
    border-left: 3px solid #ff1857;
    margin-bottom: 30px;
  }
  
.events__box-title h2 {
    color: #222222;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 40px;
    padding-left: 15px;
    margin-bottom: 0px;
}


.events__box-text {
    margin-left: 20px;
    margin-top: 10px;
}

.events__box-text h4 {
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    transition: all 300ms linear 0s;
}
.articles__box {
    background: #fff;
    padding-bottom: 2rem;
    border-radius: 5px;
    box-shadow: -1px 2px 34px -13px rgba(0,0,0,0.75);
    text-align: left;
    overflow: hidden;
}

.articles__box-image-container {
    background: none;
    float: left;
    height: 100%;
    width: 320px;
    position: relative;
}

.articles__box-image {
    height: 100%;
    width: 300px;
    padding: 30px;
}

.articles__box-title {
    background: #f9f9ff;
    border-left: 3px solid #ff1857;
    margin-bottom: 30px;
  }
  
.articles__box-title h2 {
    color: #222222;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 40px;
    padding-left: 15px;
    margin-bottom: 0px;
}


.articles__box-text {
    background-color: #fff;
    float: left;
    height: 100%;
    padding: 12px 15px;
    margin-top: 20px;
    position: relative;
    width: 65%;
}

.articles__box-text h4 {
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    transition: all 300ms linear 0s;
}
.grade-detail-label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    display: block;
}

.add-to-cart-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px;
}

.add-to-cart-close-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: grey;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px;
}

.event_detail img{
    height: 300px;
    width: 100%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.event_detail h4 {
    font-size: 36px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 20px;
    margin-top: 30px;
}

.event_detail p {
    font-size: 14px;
    color: #777777;
    margin-bottom: 15px;
    display: block;
}

.event_detail-top {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.event_detail-top-left {
    width: 66.666666%;
    align-self: center;
}

.event_detail-top-right {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    width: 33.333333%;
}

.event_detail-middle {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.event_detail-bottom {
    border-style: solid;
    border-color: #DBDAE3;
    border-width: 1px 0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}
.login_form_login_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    line-height: 115.1%;
    margin-top: 140px;
    margin-bottom: 5px;
    color: #4B41BA;
}

.login_form_click_signup_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 85%;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
}
/*
.login_form_email_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    color: #000000;
}

.login_form_email_input_detail {
    background-color: #F6F6F6;
    border-radius: 20px;
    margin-left: 10px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.login_form_password_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    margin-top: 10px;
    color: #000000;
}

.login_form_password_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    margin-left: 10px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.login_form_loin_button_location_detail {
    background: #5566F6;
    border-radius: 26px;
    margin-bottom: 10px;
    border-style: none;
}*/

.login_form_login_button_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 160%;
    line-height: 19px;
    margin-top: 10px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}


.signup_form_signup_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200%;
    line-height: 115.1%;
    margin-top: 100px;
    margin-bottom: 10px;
    color: #4B41BA;
}

.signup_form_click_login_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 85%;
    line-height: 115.1%;
    color: rgba(0, 0, 0, 0.85);
}

/*
.signup_form_user_id_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_user_id_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_email_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
    margin-top: 0px;
}

.signup_form_email_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_first_name_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_first_name_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_last_name_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_last_name_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_city_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_city_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_state_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_state_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_password_detail{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_password_input_detail {
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_confirm_password_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 85%;
    line-height: 115.1%;
    color: #000000;
}

.signup_form_confirm_password_input_detail{
    background: #F6F6F6;
    border-radius: 20px;
    border: transparent solid thin;
    outline-color: #5566F6;
}

.signup_form_signup_button_location_detail {
    background: #5566F6;
    border-radius: 26px;
    border-style: none;
    margin-bottom: 10px;
}*/

.signup_form_signup_button_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 160%;
    line-height: 19px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.signup_form_click_privacy_detail {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    margin-right: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.85);
}
.payment-label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    display: block;
}

.payment-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px;
}

.payment-input {
    margin-bottom: 20px;
    width: 200px;
}

.form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
}

.button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.input,
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.input::-webkit-input-placeholder {
    color: #aab7c4;
}

.input::placeholder {
    color: #aab7c4;
}

.input:focus,
    .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
}
.CEBasicInfo_categoryContainer__3ie_6 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, -webkit-max-content));
  grid-template-columns: repeat(auto-fill, minmax(10rem, max-content));
}
.CEBasicInfo_categoryBtn__KIFNj {
  width: 110px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2);
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px white solid;
  outline: none;
}

.CEBasicInfo_categoryBtnFocus__1D6M9 {
  width: 110px;
  height: 45px;
  background: #ffffff;
  /* box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2); */
  border-radius: 20px;
  border: 1px solid #5666f6;
  margin-right: 20px;
  margin-bottom: 20px;
  outline: none;
}

.CEBasicInfo_categoryIcon__1IAvG {
  outline: none;
  border: white;
  background-color: transparent;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset;
}

.CEBasicInfo_onlineInput__qb0m_ {
  width: 100%;
  height: 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgBjZFdDcMwDIStIRiDBUIgZAzKYIUwBoPSMtgYDMLGoGUQCO2d6kr9ceqe9D0k8vnsRMRXBTowgAYEz/AAGSQQQQu+XkLW4lmBiRc51h38F+cEequQnZ+gNu6zTrDSvAMX/oHPxlCXDHHT/ZThqiksepUM1iuxiE/7lmn5nTqjUxBH/Onb4hyN5J04RqNFSQo7iDFKq4kctfIMI8ZWJzL1dXW4AAAAAElFTkSuQmCC);
  background-position-y: center;
  background-position-x: 98%;
  background-repeat: no-repeat;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
}

.CEBasicInfo_offlineInput__ILlZl {
  width: 100%;
  height: 40px;
  background-position-y: center;
  background-position-x: 98%;
  background-repeat: no-repeat;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBjZKBEYMgDEX/dYEyAiMwgqN0g7pB3aBswCgdoSPQDeoGCndBPxg8390/MeGHSARqhqSQ9E9a5JnfLTo8ZWNPr9bwoGSuPklsTIqUG9j0lWBUWrHU7qcEDVUaoTPRHnMTU2HumH60vpfF4fiGgP17Nzz0W8pdvCkX2ORQX2+uGKmDItu24HE+Jw8Fg3omrIgTrGLUZnfAof73HC7i5ATVsAKF202XDEIdBwAAAABJRU5ErkJggg==);
}

.CEBasicInfo_eventBtn__Z6s9k {
  width: 110px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(243, 171, 171, 0.2);
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px white solid;
  outline: none;
}

.CEBasicInfo_eventBtnFocus__5SN8e {
  width: 110px;
  height: 45px;
  background: #ffffff;
  /* box-shadow: 0px 5px 18px rgba(132, 132, 132, 0.2); */
  border-radius: 20px;
  border: 1px solid #5666f6;
  margin-right: 20px;
  margin-bottom: 20px;
  outline: none;
}

.CEPhotos_uploadPhoto__2BqEa {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABECAYAAADaz4jLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASkSURBVHgB7ZtPiBtVHMe/k65QizUJam9ux4sUdWs8CIusMsV68A9ILx7aQ+LFpSKsx9VLkpN7az3UHrOLuFcDKvSWhC5WvKS2KCjSxOrJPyQp7K6Ubl5/v9lOmOwmM28m7y0z6XzgkWRm8vLeJ+/33pt5M0BCQkKCPMa4HZ2OyOAwMog42UeNNhQxJIMFpGawJAQs+mghHnSpEtX+DsrZ7GRiBjJIhGkcQo3emogphoFS+qhRRkhsGdMgwmESIbaM7h3RwhSIcBA7OEMhU0VAUp07ooApEsFQK7+AEKSoaeQxfZgU+hYCkqKUwzSSCl4vlhH5uURIAtcrhYQBiQwXiQwXiQwXiQwXM9BEr9vF+voabt64jnQmg7m5HM6ei/aURosMFvDO26fQ63WHtq98VsK339Uwe9xEFFEeJrf/aI8UYe+7PX5fFFAuY2Wl7FlZFrL+1RqiiHIZHCJ+bGzUEUXUhwn98348NGHCo4Yfs7MmoohyGTLD5/InRcjCHfLJF545kH5Gg4yCpxAWIdsynJGJQ+/D8wVsXK1DJ1pmoF9cXt1X6bmTOdpeoe0lqTzcIhzOnT0j1UGHxaDrnwIacTrLdFr+8sIoEQ6cD0/cWK4XVKtyNm2UEIBQLYOn2rJw4VWJsH+b5PJ+HS0ksAwu7KsLL9HUOvTyhGfeXiIcdAkJJMNdWD7PUClEVoSDDiHSMkYVVpWQoCIcVAuRkuFV2EmFhBXhoFQIjyZe6cbNlqAhkkccz0RDpvDLK2zeMok6aXF1oznIu9MTJQRFZWGDCFEpYpQQpTLCFlZGiA4Re4UokzFpYb2E6BThFnLpUqWEoOgq7CghByHCNE1hWZZYXFysCCEsSvLLjDoL6xaiUwRXvlKpiA6tNo+Bd9hypGToKiwL0ZV3LpcTtVpNBKRJyRwrQ3fz5RhWnefS0pKYkOI+GQcRx6pTsVgUiigOyYibiEKh4FvDv//ti9r39+xXCfJuH7ERwSNFq9XyFfH+x9vivQ+27NfNTV8h3LmaLCJWa60UHiAhnse0/+xjc0vY7/n1x+s78IEvthT5TWxksAQKEd/jNrcRBo69TGxk0FwCminERkY+n4dmLG23JKhmVF/x8699NK7dG9r2z39i6HPj2g5++a0/tO3l3CE77eFFvkNYIAaIPRfxudIfffo/wlK5cBhHjgw/VBHbO3ecESMs7b/2fz82Mrp7lifMp1N46/VwUW69MoPnnt1XdX5UAx3E4MbYZrMJOjHzPa5OfcTl1buDz+fzj9iVl6DOevSt1ymk0WhAMz+xDO2/ooJqNfATE4F/gmVcpBTNu0dc1Ot1O2mibRiGHSbd06ffVL9WqIFy2b+Yx54YHi6PPSk1RtgZ2zOPW7d+/2FhwTJoIcdChGm328hms5ifnx97zFMkY2vbvmhld5xvvObbeX5OrWJl1A7zxInna4jwaXwmkxE0sghFcEaDkXTkc63LyyXzypVvco89fjSSD+bw1PzLtcq7mOzBIR5FT1GriHx/KQX9qyURjouYRqhiJqVVCQF8Retr4bGOYmBKeBD7FnZD5zh2Z9UcAj3shkR1akIiISEhEtwHshRBDp+EEzsAAAAASUVORK5CYII=);
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position-x: center;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.CEPhotos_uploadPhotoWithExists__tqDvs {
  text-align: center;
  cursor: pointer;
  width: 190px;
  height: 133px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL8AAACFCAYAAAAKCynNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJzSURBVHgB7dlBTtxWAIDhN7gMQmzCTbpiTU/S3CTJSZqcpBwhXIAdCyQWkZAYMWBNx1G7TbtImCr/90nPfrbe8veTLa/G356fny93u927/fTX/Xgz4OdztR+f1uv1x+VitRz24b/bh/9+QMBqtXp/fHz8YbXZbN5O0/THgJB5nn9bbbfbP/fzywEtV0v8uwE9X44GNL0RP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8B/Dy8jI4PPG/sru7u3F9fT04PPG/smXXn+d5cHjiJ0v8ZImfLPGTJX6yxE/WL4Pv5unpaTw8PHxzzePj49fz/f39N9dN0zTOz88HP474v6Ml/Jubm/+09t/WnZ2dif8HW223293g1dze3n4dFxcXg8Pyzk+W+MkSP1niJ0v8ZImfLPGTJf5XdnJy4ufV/4SfXGTZ+ckSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ0v8ZImfLPGTJX6yxE+W+MkSP1niJ2uJ/8uAoCX+zwN6ro7mef4woOfT0enp6dVut/MAkLH0vl6vP67+ubHZbN5O0/T7fno54OezfNt+Xt50lg1/ufEXt0ZsHn6rUOgAAAAASUVORK5CYII=);
}

.CEPhotos_uploadContainer__uWaN2 {
  border: 1px dashed #c5c5c5;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 40px;
  min-width: 620px;
  min-height: 420px;
}

.CEPhotos_uploadContainerWithDrag__18B3r {
  border: 1px dashed #c5c5c5;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 40px;
  min-width: 620px;
  min-height: 420px;
}

.CEPhotos_displayPhoto__108BO {
  display: grid;
  width: 620px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 9px;
  padding-bottom: 9px;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;  
}
/* display: 'grid', marginTop: '2%', marginBottom: '2%', width:'95%', marginLeft: 'auto', marginRight: 'auto', gridRowGap: '30px', gridTemplateColumns: 'repeat(3, 1fr)' */

.CEDateAndTimes_menuItem__3NPRQ {
  width: 99px;
  height: 61px;
  padding: 100 40px;
  margin: 15px 10px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.11);
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CEDateAndTimes_menuItemWrapper__bzfYG{
  border: none;
  outline: none;
}
.CEDateAndTimes_menuItem-wrapper__BQUtX.CEDateAndTimes_active__2lMX3 {
  border: none;
  outline: none;
  shape-outside: none;
}
.CEDateAndTimes_menuItem__3NPRQ.CEDateAndTimes_active__2lMX3 {
  border: none;
}

.CEDateAndTimes_scroll-menu-arrow__1u0k- {
  padding: 20px;
  cursor: pointer;
}

.CEDateAndTimes_arrowPrev__X0cJs{
  border: 0.6px solid #5666F6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}
.CEDateAndTimes_arrowNext__RLdtF{
  border: 0.6px solid #5666F6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}

.CEDateAndTimes_imgStyle__h4XSu{
  width: 362; 
  max-height: 190;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.CEDateAndTimes_previewCard__9RYwH {
  background-color: white;
}

.ManageEvent_banner__1gBkW {
    background-image: url("/static/media/4 2.3deeb253.png");
    width: 100%;
    background-size: cover;
    height: 240px;
}

.ManageEvent_ticketContainer__3OJFV {
    position: relative;
    /* background-image: url("../../assets/Group\ 4725.png"); */
    /* margin-left: -50px; */
    /* width: 80%; */
    min-height: 300px;
    /* background-repeat: no-repeat; */
    /* position: absolute; */
    /* justify-content: center; */
    /* display: flex; */
    box-shadow: 0px 24px 50px rgba(184, 185, 190, 0.35);
    border-radius: 15px;
    padding: 41px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ManageEvent_ticketContainer__3OJFV::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: 30px;
    left: -20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    box-shadow: 0px 24px 50px rgba(184, 185, 190, 0.35);
    border-right: 20px solid #FFFFFF;
}

.ManageEvent_ticketSubContainer__1DHdg {
    border-radius: 7px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: #F4F5FF;
    margin-bottom: 30px;
    padding: 24px;
}

.ManageEvent_ticketSubContainer__1DHdg:last-child {
    margin-bottom: 0px;
}

.ManageEvent_ticketActions__242Su {
    position: relative;
    padding-top: 5px;
    text-align: right;
}

.ManageEvent_DayPicker-Day--gradeSoldDate__2hT8X:not(.ManageEvent_DayPicker-Day--outside__djHAk) {
    background-color: yellow;
}
.EditEvent_menuItem__tojCO {
  width: 99px;
  height: 61px;
  padding: 100 40px;
  margin: 15px 10px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.11);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.EditEvent_menuItemWrapper__dHy4u {
  border: none;
  outline: none;
}
.EditEvent_menuItem-wrapper__3WT1I.EditEvent_active__2jDku {
  border: none;
  outline: none;
  shape-outside: none;
}
.EditEvent_menuItem__tojCO.EditEvent_active__2jDku {
  border: none;
}

.EditEvent_scroll-menu-arrow___7lbi {
  padding: 20px;
  cursor: pointer;
}

.EditEvent_arrowPrev__RJYfF {
  border: 0.6px solid #5666f6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}
.EditEvent_arrowNext__2dMLr {
  border: 0.6px solid #5666f6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}

.EditEvent_imgStyle__9XDxO {
  width: 362;
  max-height: 190;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.EditEvent_previewCard__1kcp- {
  background-color: white;
}

