.menuItem {
  width: 99px;
  height: 61px;
  padding: 100 40px;
  margin: 15px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.11);
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menuItemWrapper{
  border: none;
  outline: none;
}
.menuItem-wrapper.active {
  border: none;
  outline: none;
  shape-outside: none;
}
.menuItem.active {
  border: none;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.arrowPrev{
  border: 0.6px solid #5666F6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}
.arrowNext{
  border: 0.6px solid #5666F6;
  width: 3px;
  height: 6px;
  margin-top: -10px;
}

.imgStyle{
  width: 362; 
  max-height: 190;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.previewCard {
  background-color: white;
}
